
  @keyframes typing {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .typingDots {
    display: inline-block;
    animation: typing 1s infinite;
    margin-left: 5px;
  }

  .chatbotWindow {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow: hidden;
    justify-content: space-between;
    line-height: 2;
    text-align: left;
  }
  
.chatMessages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.message {
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  max-width: 75%;
  text-align: left;
  line-height: 1.5;
  
}

.user {
  background-color: #2196f3;
  color: #ffffff;
  align-self: flex-start;
}

.bot {
  color: #333;
  align-self: flex-start;
  padding-left: 10;
}

.chatInput {
  display: flex;
  background-color: #ffffff;
  padding: 0.5rem;
  border: 1px solid #182fa1;
  font-size: medium;

}

input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 4px;
}

button {
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  background-color: #5269da;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}